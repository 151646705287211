/*========== GOOGLE FONTS ==========*/

/*========== VARIABLES CSS ==========*/
:root {
    --titleColor: #fff;
    --bodyColor: #0a1d3b;
    --bannerColor: #051836;
    --paraColor: #c9c9c9;
    --bodyFont: "Poppins", sans-serif;
    --primaryfontColr: #fff;
    --navColr: #efefef;
  }
  
  /*========== BASE ==========*/
  
  .bodyBox {
    margin: 0;
    padding: 0;
    font-family: var(--bodyFont);
    background-color: var(--bodyColor);
    color: var(--primaryfontColr);
  }
  
  .paraColor {
    color: var(--paraColor);
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
  }
  .bannerBox {
    background: url("../images/bannerBg.png") var(--bannerColor);
    background-position: center bottom;
    padding: 50px 0;
    min-height: calc(100vh - 10px);
    display: flex;
    align-items: center;
    margin-top: -100px;
  }
  a,
  a:hover {
    text-decoration: none;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  /*========== HEADER ==========*/
  .page-header {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    padding: 20px 0;
    width: 100%;
  }
  .page-header__top {
    display: flex;
    justify-content: space-between;
    padding: 0 0px;
  }
  
  .navigation {
    height: 0;
    transition: 0.7s;
  }
  .navigation__item {
    padding: 0 12px;
  }
  .navigation__item a {
    color: var(--navColr);
    font-weight: 300;
    font-size: 14px;
  }
  .navigation__item a:hover {
    color: #fff;
  }
  .navigation--visible {
    opacity: 1;
    height: 200px;
  }
  
  .loginButton {
    border: 1px solid #384c6d;
    border-radius: 4px;
    background-color: #587eb91f;
    padding: 8px 24px !important;
    transition: 0.4s;
  }
  .commonButton {
    border: 1px solid #384c6d;
    border-radius: 4px;
    background-color: #587eb91f;
    padding: 12px 24px;
    color: var(--navColr);
    font-weight: 300;
    transition: 0.4s;
  }
  .commonButton:hover,
  .loginButton:hover {
    border: 1px solid #1c355f;
    background-color: #05132f;
    color: #fff;
  }
  .bannerTitle {
    font-size: 42px;
    font-weight: 600;
  }
  .bannerTitle {
    font-size: 42px;
    font-weight: 600;
  }
  .bannerpara {
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    margin-top: 20px;
  }
  .availableTiltle {
    font-size: 26px;
    font-weight: 500;
  }
  .comingSoon {
    color: var(--navColr);
    font-style: italic;
    font-weight: 300;
    margin-top: 10px;
  }
  
  .appLink {
    border: 1px solid #384c6d;
    border-radius: 4px;
    background-color: #587eb91f;
    padding: 10px 17px;
    color: var(--navColr);
    font-weight: 300;
    transition: 0.4s;
    margin-right: 5px;
  }
  .appLink:hover {
    border: 1px solid #1c355f;
    background-color: #05132f;
    color: #fff;
  }
  
  .subHeading {
    border: 1px solid #384c6d;
    border-radius: 4px;
    background-color: #587eb91f;
    padding: 8px 16px;
    color: var(--navColr);
    font-weight: 300;
    transition: 0.4s;
    width: auto;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 13px;
  }
  
  .mainTitle2 {
  }
  .sectionpadding {
    padding: 80px 0;
  }
  .joinBox {
    background: url(../images/joinBg.png) var(--bannerColor) no-repeat;
    background-position: center right;
    padding: 30px 25px;
    border-radius: 6px;
  }
  .joinTitle {
    margin: 10px 0;
    font-size: 26px;
  }
  .ceoName {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    text-align: center;
  }
  .ceoName b {
    font-size: 20px;
    text-align: center;
    display: block;
    margin-bottom: 7px;
  }
  .borderBox {
    border: 1px solid #374865;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 6px;
    display: flex;
    flex-wrap: wrap;
  }
  .font-20 {
    font-size: 18px;
  }
  .awrdBox {
    border: 1px solid #374865;
    padding: 30px 20px;
    border-radius: 4px;
    margin-bottom: 6px;
    height: 100%;
  }
  .awrdBox h6 {
    margin-bottom: 15px;
  }
  .awrdFlexBox {
    display: flex;
    flex-wrap: wrap;
  }
  .awrdSubBox:first-child {
    width: 67%;
    margin-right: 3%;
  }
  .awrdSubBox:nth-child(2) {
    width: 30%;
  }
  .awardTitle {
    color: #a1a9ad;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 6px;
  }
  .awrdCntnt {
    font-size: 14px;
    color: #d7d7d7;
  }
  .footerSection {
    background: url(../images/bannerBg.png) var(--bannerColor);
    background-position: center bottom;
    padding: 50px 0;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .font-13 {
    font-size: 13px;
  }
  .footerNav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .footerNav li {
    padding: 0 4px;
    list-style: none;
  }
  .footerNav li a {
    font-size: 13px;
    color: var(--paraColor);
  }
  .stocks.nav-tabs {
    border-bottom: 1px solid #384c6d !important;
    padding-block: 10px;
  }
  .stocks.nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    color: #fff;
    padding: 10px;
  
    border: none;
  }
  
  .stocks.nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active,
  .stocks.nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link:hover {
    color: #316bc9;
    background-color: transparent;
    border: none;
  }
  .stockUl li{
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #384c6d;
    border-radius: 4px;
    padding: 15px;
    align-items: center;
    margin-bottom: 15px;
  }
  .stockUl
  {
    padding: 0;
    margin-top: 20px;
  }
  .stockTitle
  {
    font-size: 20px;
    margin-bottom: 20px;
  }.stockImg
  {
    width: 200px;
  }
  
  .stckCntnt
  {
    width: calc(100% - 220px);
    margin-left: 20px;
  }
  /* ************************************************************ */
  @media (min-width: 767.9px) {
    .page-header {
      display: flex;
    }
    .page-header__top {
      margin-right: auto;
    }
    .page-header__top,
    .page-header__bottom {
      display: block;
    }
    .page-header__toggle {
      display: none;
    }
    .navigation {
      display: block;
    }
    .navigation__item {
      display: inline-block;
    }
  }
  /* ************************************************************ */
  @media (max-width: 768px) {
  
    .stockUl li{
      display: block;
    }
    .stckCntnt {
      width: calc(100% - 0px);
      margin-left: 0px;
  }
    .borderBox .col-md-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      width: 33.333333%;
    }
    .borderBox .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
      width: 25%;
    }
    .borderBox .col-md-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
      width: 41.666667%;
    }
    .mdflex-column-reverse {
      flex-direction: column-reverse;
    }
    .nav > .nav-header {
      padding: 0;
    }
    .nav > .nav-btn > label {
      margin-top: 15px;
      margin-right: 10px;
      cursor: pointer;
    }
    .navigation {
      overflow: hidden;
      background-color: #1f3c69;
      border-radius: 6px;
      height: 0;
      overflow: hidden;
    }
    .navigation__item {
      padding: 6px 12px;
    }
    .navigation.navigation--visible {
      height: auto;
      overflow: hidden;
      padding: 20px;
      margin-top: 10px;
    }
    .page-header__toggle {
      font-size: 24px;
      cursor: pointer;
      display: block;
    }
  }
  
  @media (max-width: 528px) {
    .awrdSubBox {
      width: 100% !important;
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  