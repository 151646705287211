@charset "UTF-8";
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

:root {
  --primarycolor: #620000;
  --navMobile: #620000;
  --white: #fff;
  --offwhite: #e5e5e5;
  --navlink: #fff;
  --footerTextColr: #999b9f;
  --primaryfont: "Poppins", sans-serif;
  --hrdash: #3a3a3a;
  --offwhite: #d6d6d6;
}
.MuiPaginationItem-page {
  color: rgba(120, 190, 211, 0.803) !important;
}
.banner .header {
  position: relative;
}
/* .banner .header::after {
  content: "";
  background: url("../images/fade2.png") no-repeat;
  position: absolute;
  width: 818px;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 1;
} */
.banner .header::before {
  content: "";
  /* background: url("../images/fade1.png") no-repeat; */
  position: absolute;
  width: 818px;
  height: calc(100% - 100px);
  top: 100px;
  left: 100px;
  opacity: 0.7;
  z-index: 1;
}
.bglight {
  background-color: var(--bglight);
}
.bgbody {
  background-color: var(--bodycolor);
}
.themecolr {
  color: var(--primary-color);
}
/* ::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: var(--white-background-color);
}
::-webkit-scrollbar-thumb {
  background: var(--primaryOffColor);
} */
/* ::selection {
  background: #fff;
  text-shadow: none;
  color: var(--primarycolor);
}
moz::selection {
  background: #fff;
  text-shadow: none;
  color: var(--primarycolor);
} */
html {
  touch-action: manipulation;
  scroll-behavior: smooth;
}
html,
body {
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
}
body.banner {
  color: var(--para-color);
  background: #0b0720;
  background: linear-gradient(200deg, #620000 0%, #0f1e35 100%);
  font-size: 15px;
  letter-spacing: 0;
  line-height: 1.7;
  font-family: var(--primaryfont);
  overflow-x: hidden;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  width: 100%;
  position: relative;
  letter-spacing: 0.2px;
  min-height: 100vh;
}
.animatn1,
.animatn2,
.animatn3 {
  position: absolute;
}
.animatn1 {
  top: 200px;
  left: 30px;
  -webkit-animation: animatntranslate 8s infinite;
  animation: animatntranslate 8s infinite;
}
.animatn2 {
  top: 100px;
  left: 0px;
  -webkit-animation: animatnzoom 4s infinite;
  animation: animatnzoom 4s infinite;
}
.animatn3 {
  top: 100px;
  right: 0px;
  -webkit-animation: animatnzoom 4s infinite;
  animation: animatnzoom 4s infinite;
}
.sectionTesimonial .animatn3 {
  top: 400px;
}
@-webkit-keyframes animatntranslate {
  0% {
    transform: translate(-20px, 0px);
  }
  50% {
    transform: translate(-20px, -80px);
  }
  100% {
    transform: translate(-20px, 0px);
  }
}
@keyframes animatnzoom {
  0% {
    transform: translate(42px, 18px);
  }
  50% {
    transform: translate(42px, 18px);
  }
  100% {
    transform: translate(42px, 18px);
  }
}
@-webkit-keyframes animatnzoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes animatnzoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.vedioClipBox {
  height: 100vh;
  display: flex;
  opacity: 0.2;
  align-items: center;
}
.vedioClip {
  /* -webkit-mask-image: url(../images/B.svg);
  mask-image: url(../images/B.svg); */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: 18% 61%;
  -webkit-mask-size: 18% 61%;
  mask-position: 31% 75%;
  -webkit-mask-position: 31% 75%;
  position: absolute;
  transform: rotate(19deg);
}
.bpr svg path {
  fill: none;
  stroke: var(--primary-color);
  stroke-width: 2;
  stroke-dasharray: 2500;
  stroke-dashoffset: 2500;
  -webkit-animation: dash 4s linear forwards;
  animation: dash 4s linear forwards;
}

.svg2 svg {
  position: absolute;
  width: 240px;
  left: 0;
  right: 0;
  margin: auto;
  top: -122px;
  transform: rotate(290deg);
}
.svg3 svg {
  position: absolute;
  width: 100px;
  left: 150px;
  margin: auto;
  top: -152px;
  -webkit-animation: animatnzoom 4s infinite;
  animation: animatnzoom 4s infinite;
}
.in-view path {
  fill: none;
  stroke: var(--primary-color);
  stroke-width: 2;
  stroke-dasharray: 2500;
  stroke-dashoffset: 2500;
  -webkit-animation: dash 4s linear forwards;
  animation: dash 4s linear forwards;
}
@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
img {
  width: 100%;
  max-width: 100%;
}

.page-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: var(--bodycolor);
  z-index: 1005;
  top: 0;
}
.loader,
.loader:before,
.loader:after {
  background: var(--bodycolor);
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: var(--primary-color);
  text-indent: -9999em;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}
.animated.flipOutX,
.animated.flipOutY {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}
@-webkit-keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}
@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}
@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}
@-webkit-keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}
@-webkit-keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}
@-webkit-keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}
/* ************ */
.wtrefct {
  fill: var(--primaryOffColor);
  position: absolute;
  left: -90px;
  z-index: -1;
  transform: scale(0.9);
  top: -110px;
}

.circle-1[data-v-1] {
  -webkit-animation: from0to360-1 3s linear infinite;
  animation: from0to360-1 3s linear infinite;
  transform-origin: 145px 150px;
}
.circle-2[data-v-1] {
  -webkit-animation: from360to0-1 4s linear infinite;
  animation: from360to0-1 4s linear infinite;
  transform-origin: 155px 150px;
}
.circle-3[data-v-1] {
  -webkit-animation: from0to360-1 5s linear infinite;
  animation: from0to360-1 5s linear infinite;
  transform-origin: 150px 145px;
}
.circle-4[data-v-1] {
  -webkit-animation: from360to0-1 4.5s linear infinite;
  animation: from360to0-1 4.5s linear infinite;
  transform-origin: 150px 155px;
}
@-webkit-keyframes from0to360-1 {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes from0to360-1 {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes from360to0-1 {
  0% {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes from360to0-1 {
  0% {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
/* *************************************** */
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.banner p {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 15px;
  line-height: 24px;
}
.banner a {
  text-decoration: none;
  background-color: transparent;
}
.banner img {
  vertical-align: middle;
  border-style: none;
  width: auto;
}
.bannrDtailmainBox img {
  animation: scale_up_down 4s ease-in-out infinite alternate both;
}
.video-wrapper {
  height: 100vh;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
}
.video-element {
  opacity: 0.07;
  max-width: 100%;
}

.instapage {
  background: var(--primary-color);
  margin-top: 100px;
  width: 10px;
  height: 10px;
  display: block;
  transition: all ease-in-out 2s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
}

.grow2.instapage {
  width: 100%;
  height: 200vh;
  border-radius: 0%;
  background: var(--bodycolor);
}

.banner label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.banner button {
  border-radius: 0;
}
.banner button:focus:not(:focus-visible) {
  outline: 0;
}
.banner button,
.banner input,
.banner select,
.banner textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.banner button,
.banner input {
  overflow: visible;
}
.banner button,
.banner select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
.banner select {
  word-wrap: normal;
}
[type="button"],
[type="reset"],
[type="submit"],
.banner button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
.banner button:not(:disabled) {
  cursor: pointer;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
.banner button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.banner input[type="checkbox"],
.banner input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.banner textarea {
  overflow: auto;
  resize: vertical;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
.banner h1,
.banner h2,
.banner h3,
.banner h4,
.banner h5,
.banner h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.banner .container,
.banner .navcontainer {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.logoFixedConatiner {
  position: absolute;
  height: 100vh;
  width: 100%;
  left: 0;
  z-index: 2;
  width: 90px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
@media (min-width: 576px) {
  .banner .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .banner .container {
    max-width: 765px;
  }
}
@media (min-width: 992px) {
  .banner .container,
  .banner .navcontainer {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .banner .container,
  .banner .navcontainer {
    max-width: 1140px;
  }
}

@media (min-width: 1520px) {
  .banner .container,
  .banner .navcontainer {
    max-width: 1280px;
  }
}

.banner .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--para-color);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .banner .form-control {
    transition: none;
  }
}
.banner .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.banner .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.banner .form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.banner .form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.banner .form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.banner .form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.banner .form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.banner .form-control:disabled,
.banner .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.banner input[type="date"].form-control,
.banner input[type="datetime-local"].form-control,
.banner input[type="month"].form-control,
i.banner nput[type="time"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.banner select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.banner select.form-control[multiple],
.banner select.form-control[size] {
  height: auto;
}
.banner textarea.form-control {
  height: auto;
}
.banner .form-group {
  margin-bottom: 1rem;
}
.banner .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .banner .btn {
    transition: none;
  }
}
.banner input[type="button"].btn-block,
.banner input[type="reset"].btn-block,
.banner input[type="submit"].btn-block {
  width: 100%;
}
.banner .banner .text-center {
  text-align: center;
}
.deeper-morph {
  position: absolute;
  left: auto;
  right: -127%;
  top: 0;
  transform: translate(1%, -87%);
  height: 300px;
}
.deeper-morph svg {
  float: right;
  height: 100%;
}

.banner .logo {
  /* width: 100px; */
  margin-right: 60px;
}
.banner .banner .banner .logo img {
  width: auto;
  height: 25px;
}

.banner .navbar {
  align-items: center;
  top: 0;
  display: flex;
  flex-direction: row;
  padding: 20px 15px;
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.banner .sticky {
  position: fixed !important;
  top: 0px;
  width: 100%;
  backdrop-filter: blur(8px);
  background-color: rgb(8 20 28 / 86%);
  z-index: 999;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 10px 15px;
}

/* Menu */

.hamburger {
  background: transparent;
  border: none;
  cursor: pointer;
  display: none;
  outline: none;
  height: 20px;
  position: relative;
  width: 20px;
}

.hamburger-line {
  background: #e5e5e5;
  height: 2px;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-out;
  width: 100%;
}
.hamburger:hover .hamburger-line {
  background: #fff;
}
.hamburger-line-top {
  top: 3px;
}
.banner .menu-active .hamburger-line-top {
  top: 50%;
  transform: rotate(45deg) translatey(-50%);
}
.hamburger-line-middle {
  top: 50%;
  transform: translatey(-50%);
}
.menu-active .hamburger-line-middle {
  left: 50%;
  opacity: 0;
  width: 0;
}
.hamburger-line-bottom {
  bottom: 3px;
}
.banner .menu-active .hamburger-line-bottom {
  bottom: 50%;
  transform: rotate(-45deg) translatey(50%);
}
.banner .navbar .push-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.banner .nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: all 0.25s ease-in;
  align-items: center;
}

.banner .flexBox {
  display: flex;
  flex-wrap: wrap;
}

.banner .loginBtn {
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  border: 1px solid rgb(28 15 95);
  border-radius: 5px;
  text-align: center;
  padding: 6px 17px;
  margin-left: 10px;
  font-weight: 600;
}
.banner .loginBtn:hover {
  color: #dddddd;
}
.banner .nav-menu .menu-item a {
  color: var(--navlink);
  display: block;
  line-height: 30px;
  margin: 0px 10px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}
.banner .nav-menu .menu-item a:hover,
.banner .nav-menu .menu-item.active a {
  opacity: 0.8;
}

.banner .sub-nav {
  border: 1px solid #ccc;
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 5px 5px;
  list-style: none;
  width: 230px;
}

.banner .nav__link:hover + .sub-nav {
  display: block;
}

.banner .sub-nav:hover {
  display: block;
}
.bannerHomeLeftContnt {
  position: relative;
  z-index: 3;
}

.fadeBg {
  position: absolute;
  right: 0;
  bottom: -110px;
  width: 220px;
  height: 260px;
  background-color: #110a36;
  border-radius: 20px;
  opacity: 0.8;
  animation: pulse 5s ease-in-out infinite alternate both;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
.bgcircle2 {
  position: absolute;
  right: 70px;
  bottom: 0px;
  opacity: 0.1;
  animation: scale_up_down 5s ease-in-out infinite alternate both;
}
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes scale_up_down {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes scale_up_down {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.bannerSectionHome {
  padding: 60px 0;
  min-height: calc(100vh - 150px);
  position: relative;
  display: flex;
  align-items: center;
}
.teamSection {
  padding: 60px 0;
  min-height: calc(100vh - 150px);
  position: relative;
  display: flex;
  align-items: center;
}
.faqSection {
  padding: 60px 0;
  min-height: calc(100vh - 150px);
  position: relative;
  display: flex;
}

.bannerSection {
  height: calc(100vh - 0px);
  color: #fff;
  display: flex;
  align-items: center;
}
.LeftSubContnt {
  padding-top: 70px;
}
.LeftSubContnt h1 {
  color: var(--white);
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 15px;
}
.LeftSubContnt h2 {
  color: var(--offwhite);
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 20px;
  font-style: italic;
}

.bannerBtn {
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  border: 1px solid rgb(28 15 95);
  border-radius: 5px;
  text-align: center;
  padding: 7px 17px;
  float: left;
  margin-top: 21px;
}
.bannerBtn:hover {
  color: #dddddd;
}
.appLinkBox {
  display: block;
  width: 100%;
  padding-top: 70px;
  position: relative;
  z-index: 3;
  float: left;
}

.appLinkBox h3 {
  color: var(--white);
  font-weight: 400;
}
.appLinkBox ul {
  margin-top: 15px;
}
.appLinkBox ul li {
  float: left;
}
.appLinkBox ul li a {
  color: var(--white);
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.appLinkBox ul li a:hover {
  opacity: 0.7;
  text-decoration: underline;
}
.appLinkBox ul li img {
  width: 22px;
  margin-right: 7px;
}

/* .bannrDtailmainBox {
  position: relative;
} */
/* .bannrDtailmainBox:before {
  content: "";
  position: absolute;
  z-index: 1;
  background: url("../images/bgcircle.png");
  width: 240px;
  height: 76px;
  top: -50px;
  right: 0;
  opacity: 0.1;
  animation: scale_up_down 5s ease-in-out infinite alternate both;
} */
.bannrDtailBox {
  border-radius: 20px;
  background: #110a36;
  background: linear-gradient(90deg, #0b0720 0%, #160e44 100%);
  max-width: 320px;
  width: 100%;
  color: #fff;
  position: relative;
  z-index: 3;
}
.bannrCustomer {
  border-bottom: 1px solid #1d51df;
  padding: 30px;
  margin-bottom: 30px;
}
.bannrCustomer h4 {
  font-size: 21px;
  font-weight: 400;
}
.bannrCustomer p {
  font-weight: 900;
  font-size: 30px;
  margin-bottom: 0;
}
.aligncenter {
  align-items: center;
}
.bannercaption {
  color: var(--para-color);
  font-size: 17px;
}
.bannrOtherDetls {
  padding: 0 30px 30px;
  margin-bottom: 30px;
}
.bannrOtherDetls h5 {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 4px;
}
.bannrOtherDetls p {
  font-weight: 600;
  font-size: 21px;
  margin-bottom: 20px;
}
.bannerfooternav {
  display: flex;
  /* flex-wrap: wrap; */
}
.bannerfooternav li a {
  color: var(--white);
  padding: 4px 14px;
  font-size: 12px;
  position: relative;
  font-weight: 300;
}
.bannerfooternav li a:hover {
  opacity: 0.8;
  text-decoration: underline;
}
.bannerfooternav li a:after {
  content: "";
  height: 5px;
  width: 5px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: -2px;
  top: 11px;
}
.bannerfooternav li:first-child a:after {
  display: none;
}
.banner .clearfix {
  display: block;
  min-height: 1px;
  width: 100%;
  clear: both;
}
.bannerFooter {
  position: relative;
  z-index: 3;
}
.copyright {
  color: var(--white);
  text-align: right;
  font-size: 12px;
  font-weight: 300;
  padding: 4px 14px;
}
.footer-address {
  padding: 4px 14px;
  font-size: 12px !important;
  position: relative;
  font-weight: 300;
}

.footer-copyright {
  padding: 4px 14px;
  font-size: 12px !important;
  position: relative;
  font-weight: 300;
}
/* **************************************************************** */
@media (max-width: 1480px) {
  .fadeBg {
    bottom: -80px;
    width: 194px;
    height: 230px;
  }
}
/* **************************************************************** */
@media (max-width: 1280px) {
}
/* **************************************************************** */

@media (max-width: 1200px) {
}
/* **************************************************************** */

@media (max-width: 1070px) {
}
/* **************************************************************** */

@media (max-width: 991.98px) {
}
/* **************************************************************** */

@media (min-width: 768.1px) {
  .mobileLogo {
    display: none;
  }
}
/* **************************************************************** */

@media (max-width: 768px) {
  .mobileLogo {
    position: absolute;
    right: 0;
  }
  .banner .navbar {
    background-color: rgb(8 20 28 / 86%);
    min-height: 60px;
  }
  .hamburger {
    display: inline-block;
  }

  .nav-menu .menu-item a {
    font-size: 14px;
    margin: 4px 15px;
    line-height: 24px;
  }
  .banner .nav-menu {
    flex-direction: column;
    opacity: 0;
    position: absolute;
    top: -30px;
    left: -30px;
    transform: translatey(-100%);
    text-align: left;
    align-items: flex-start;
    height: 100vh;
    width: 250px;
    padding-top: 50px;
    background-color: var(--navMobile);
    z-index: 5;
  }
  .banner .loginBtn {
    margin-top: 20px;
  }
  .menu-active .nav-menu {
    transform: translatey(0%);
    opacity: 1;
  }
  .banner .logo {
    display: none;
  }
  .menu-active #menu-toggler {
    left: 170px;
    top: -10px;
    position: absolute;
    z-index: 9;
  }
  body.banner {
    min-height: auto;
  }
  /* .banner .header::after {
    content: "";

    position: absolute;
    width: 818px;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    z-index: 1;
  } */
  .banner .header::before,
  .banner .header::after,
  .fadeBg,
  .bgcircle2 {
    display: none;
  }
  .LeftSubContnt {
    padding-top: 70px;
    width: 100%;
  }
  .bannrDtailBox {
    margin-top: 46px;
    max-width: 100%;
  }
  .bannerfooternav li a {
    color: var(--white);
    padding: 4px 8px;
    font-size: 12px;
    position: relative;
  }
  .banner .copyright {
    font-size: 12px;
    margin-left: 8px;
    text-align: left;
  }
  .bannerSectionHome {
    padding: 20px 0;
    min-height: 100%;
  }
  .bannrDtailmainBox:before {
    top: 0;
  }
}
/* **************************************************************** */

@media (min-width: 576.1px) {
  .banner .d-sm-none {
    display: none;
  }
}
/* **************************************************************** */

@media (max-width: 576px) {
  .innerBannerSection.aboutBannerBg,
  .innerBannerSection {
    height: 420px;
    align-items: end;
  }
  .brandServc li {
    width: calc(100% / 2 - 10px);
    margin: 5px;
  }
  .brandBox .mainCaption {
    font-size: 37px;
  }
  .Leaders .card {
    width: 320px;
  }
  .teamLeaders li {
    width: 320px;
    margin: auto;
  }
  .teammembers li {
    width: 320px;
    height: 340px;
    margin: auto;
  }
  .teammembers li:nth-child(odd) {
    border-radius: 0 65px 0 30px;
    margin: 15px auto 2px;
    border: 1px solid var(--primary-color);
  }
  .teammembers li:nth-child(even) {
    border-radius: 30px 0px 65px 0;
    margin: 2px auto 20px;
    border: 1px solid var(--secondary-color);
  }
  .AboutIntroImgBox {
    width: 360px;
  }
  .AboutIntroBottomBox {
    height: 122px;
    font-size: 18px;
    line-height: 28px;
  }
  .jobOpening > li {
    width: calc(100% / 1 - 30px);
  }
  .jobHeader li {
    width: calc(100% / 1 - 0px);
    max-width: calc(100% / 1 - 0px);
    margin: 0;
  }
  .blogOverlay {
    padding: 5px 15px 5px;
  }

  .blogtitle {
    font-size: 13px;
    max-height: 18px;
  }
  .blogDate {
    margin-top: 5px;
    width: 46px;
    line-height: 19px;
    padding: 1px;
    font-size: 13px;
  }
  .statusSubBox {
    width: calc(100% / 1 - 0px);
    margin: 0px;
  }
  .footerproductUl li {
    width: calc(100% / 1 - 0px);
    margin: 0 0 0 0;
  }
  .serviceListBox ul li {
    width: calc(100% / 1 - 0px);
    margin: 10px 0;
  }
  .innerBannerSection {
    height: 440px;
  }

  .cleints li {
    width: calc(100% / 1 - 30px);
    margin: 20px 15px;
  }
  .mainTilte {
    font-size: 27px;
    letter-spacing: 2px;
  }
}
/* **************************************************************** */

@media (max-width: 495px) {
  .mask1 {
    mask-size: 100%;
    -webkit-mask-size: 100%;
  }
  .imghr {
    left: 10px;
  }
  .talkBox {
    padding: 25px 10px;
    padding-left: 125px;
  }
  .talkCntntBoxBox b {
    font-size: 14px;
  }
  .talkcontact p span {
    display: block;
    font-size: 13px;
  }
  .talkcontact span:last-child {
    border-left: 0px solid var(--offwhite);
    padding-left: 0px;
    margin-left: 0px;
  }
}

/* animation  */

.animate-charcter {
  background-image: linear-gradient(
    -225deg,
    #fff 0%,
    #fff 29%,
    #6d91ca 20%,
    #fff 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 10s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.teamMainDiv {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.teamHead {
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: 1rem !important;
}
.teamImage {
  width: 70px;
  display: block;
  margin: auto;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.teamImage:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.teamSubtile {
  margin-top: 5px !important;
}
.teamList {
  text-align: center;
  width: 180px;
  margin-top: 1rem;
}
#rcc-confirm-button {
  width: 90%;
}
#rcc-decline-button {
  width: 90%;
}
.custom-button {
  /* Add your custom styles here */
  background-color: #ff0000;
  color: #ffffff;
  font-size: 16px;
  /* Add any other styles you want */
}
